<template>
    <div class="page_box" v-loading="loading">
        <div class="form_box">
            <div class="form-ctx">
                <div class="form_flex">
                    <div class="lefts">
                        缴存基数
                    </div>
                    <div class="rights">
                         {{form.jc_jishu||0}}
                    </div>
                </div>
                <div class="form_flex">
                    <div class="lefts">
                        缴额
                    </div>
                    <div class="rights">
                         {{form.jc_money||0}}

                    </div>
                </div>

                <div class="form_flex">
                    <div class="lefts">
                        单位缴纳
                    </div>
                    <div class="rights">
                        {{form.jc_company||0}}

                    </div>
                </div>
                <div class="form_flex">
                    <div class="lefts">
                        个人缴纳
                    </div>
                    <div class="rights">
                        {{form.jc_user||0}}

                    </div>
                </div>


                <div class="form_flex" v-for="(item, index) in form_baoxian" :key="index">
                    <div class="lefts">
                        {{ title_obj[item.type] }}：
                    </div>
                    <div class="rights3">
                        <div class="items">{{item.fee||0}}</div>
                        <div class="items">{{item.payable||0}}</div>
                        <div class="items">{{item.reduction||0}}</div>
                        <div class="items">{{item.refund||0}}</div>
                       
                    </div>
                </div>
                <div class="form_flex">
                    <div class="lefts">
                        商业意外险：
                    </div>
                    <div class="rights4">
                        {{ form.syyw_insure==1?'是':(form.syyw_insure==0?'否':'') }}
                       
                    </div>
                </div>
                <div class="form_flex">
                    <div class="lefts">
                        安全生产责任险：
                    </div>
                    <div class="rights4">
                        {{ form.syyw_insure==1?'是':(form.syyw_insure==0?'否':'') }}
                    </div>
                </div>
            </div>

           
        </div>
    </div>
</template>
<script>

export default {
    name: "Customer_Information_form",
    props: {

    },
    data() {
        return {
            loading: false,
            shows: false,
            user_id: 0,
            modal_title: '社保增员',
            shows: false,
            form: {
                id: 0,
                socNo: '',
                jc_jishu: '',
                syyw_insure: "1",
                aqzr_insure: "1",
                social: '',
            },

            form_baoxian: [{

                type: 10,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 11,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 20,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 21,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 30,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 31,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 40,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 41,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 50,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 51,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 60,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 61,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 70,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }, {

                type: 71,
                fee: "",
                payable: "",
                reduction: "",
                refund: ""
            }],


            title_obj: {
                10: '养老保险(单位缴纳)',
                11: '养老保险(个人缴纳)',
                20: '医疗保险(单位缴纳)',
                21: '医疗保险(个人缴纳)',
                30: '工伤保险(单位缴纳)',
                31: '工伤保险(个人缴纳)',
                40: '失业保险(单位缴纳)',
                41: '失业保险(个人缴纳)',
                50: '大额医疗(单位缴纳)',
                51: '大额医疗(个人缴纳)',
                60: '公积金(单位缴纳)',
                61: '公积金(个人缴纳)',
                70: '企业年金(单位缴纳)',
                71: '企业年金(个人缴纳)',
            },

            //
            user_list: [],
            user_info: '',


        };
    },
    methods: {
        get_detial() {
            this.loading = true
            this.$api("socialEmpList", {
                userId: this.$route.query.id
                // userId:432

            }, "post").then((res) => {
                this.loading = false
                console.log('工资记录', res.data)
                if (res.code == 200) {
                    if (res.data.list.length > 0) {
                        // this.form = res.data.list[0]
                        this.init(res.data.list[0])
                    }
                } else {
                    alertErr(res.msg)
                }
            });
        },
        
        init(row) {
            this.shows = true
            if (row) {
              
                this.modal_title = '员工社保信息编辑'
                row.syyw_insure = row.syyw_insure + ''
                row.aqzr_insure = row.aqzr_insure + ''
                this.form = row
                this.form_baoxian.forEach((e, i) => {

                    let type_text = 'social' + e.type
                    if (row[type_text]) {
                        let obj = row[type_text]

                        this.form_baoxian[i] = obj
                    }
                    // else{
                    // 	this.form_baoxian[i]={
                    // 		type:e.type
                    // 	}
                    // }
                })
                console.log(row)

            } else {
                this.modal_title = '社保增员'
            }
        },

    },
    computed: {
    },

    watch: {

    },

    created() {
        this.get_detial()

    },


};
</script>

<style scoped lang="less">
.page_box {
    padding-top: 50px;

    .form_box {
        width: 795px;
        margin: auto;
        margin-top: 0;

        

        .form_flex {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .lefts {
                width: 170px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                text-align: right;
                padding-right: 20px;
            }

            .lefts1 {
                width: 150px;
            }

            .rights {
                // width: 446px;
                flex: 1;
                height: 39px;
                border-radius: 4px 4px 4px 4px;
                padding: 0 12px;
                width: 100%;
                height: 40px;
                border: 1px solid #D4D4D4;
                display: flex;
                align-items: center;
                
            }

            .rights3 {
                flex: 1;
                height: 39px;
                border-radius: 4px 4px 4px 4px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .items{
                    height: 39px;
                    width: 127px;
                    border: 1px solid #D4D4D4;
                    border-radius: 2px 2px 2px 2px;
                    display: flex;
                    align-items: center;
                    padding: 0 12px;

                }
                
            }

            .rights4 {
                flex: 1;
                height: 39px;
                border-radius: 4px 4px 4px 4px;
                display: flex;
                align-items: center;


            }

            
        }

    }
}
</style>